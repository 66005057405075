import React from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill"
import { Helmet } from "react-helmet";
import { ButtonArrowRight } from "../components/Button";
import * as Markdown from 'react-markdown';
import Layout from "../components/layout";
import SEO from "../components/seo";
import { customColor, slugify, collectionDisplay } from "../lib/helpers";
import HeroCarousel from "../components/Content/HeroCarousel"
import style from "../components/Content/Content.module.css";
// import heroBG from '../images/homepage-hero.jpg';


const LatestPosts = ({ blog }) => {
    const postNodes = blog.sort((a, b) => new Date(b.node.publishDate).getTime() - new Date(a.node.publishDate).getTime());

    return (
        <section className="uk-section uk-section-large uk-background-secondary uk-padding-remove-bottom uk-margin-xxsmall">
            <div className="uk-container uk-container-expand">
                <div className="uk-container uk-text-center uk-light uk-margin-xlarge">
                    <p className="h3__large uk-text-earth-light uk-margin-xsmall">The knowledge to make you smarter.</p>
                    <p className="uk-text-lead uk-text-earth-lighter uk-margin-remove">We dig deeper to bring insight in the moment.</p>
                </div>
                <div className="uk-position-relative" data-uk-slider="autoplay: true;autoplay-interval: 5000;pause-on-hover: false">
                    <div className="uk-slider-container">
                        <div className="uk-slider-items uk-grid uk-grid-collapse uk-child-width-1-3@m" uk-height-match="target: .uk-card-body">
                            {postNodes.slice(0, 6).map((post, i) =>
                                <div key={post.node.slug}>
                                    <div className={`uk-card uk-padding-large uk-padding-remove-top uk-padding-remove-horizontal ${customColor(post.node.sectionColor) + '-dark'}`}>
                                        <div className="uk-card-media-top cursor-hand">
                                            <Link to={`/feed/${post.node.slug}/`}>
                                                <Img
                                                style={{ height: "344px" }}
                                                fluid={post.node.heroImage.localFile.childImageSharp.fluid}
                                                alt={post.node.title}
                                                objectFit="cover"
                                                objectPosition="50% 50%" /></Link>
                                        </div>
                                        <div className="uk-card-body uk-light uk-position-relative uk-transition-toggle">
                                            {/* <div className="uk-overlay-transparent uk-position-cover uk-transition-fade cursor-hand" /> */}
                                            <Link to={`/feed/${post.node.slug}/`} className="uk-link-reset"><h2>{post.node.title}</h2></Link>
                                            <p className="uk-text-large">
                                                {post.node.copy.childMarkdownRemark.rawMarkdownBody}
                                            </p>
                                        </div>
                                        <div className="uk-card-footer uk-light">
                                            <ButtonArrowRight
                                                url={`/feed/${post.node.slug}/`}
                                                label="Read this"
                                                css={`uk-button-large cta-button uk-text-blue-lightest`}
                                                color={`#fff`} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <ul className="uk-position-bottom-center uk-position-medium uk-slider-nav uk-dotnav" />
                </div>
            </div>
        </section>
    )
}

const CardCollection = ({ data, cards }) => {

    const dis = collectionDisplay(data.displayFormat)
    let backgroundColor = customColor(cards.sectionColor)
    backgroundColor = backgroundColor !== "None" ? backgroundColor : null
    const displayWidth = data.displayWidth;

    return (
        <section className={`${backgroundColor ? backgroundColor : ""} uk-margin-xxsmall-bottom`}>
            <div className={`uk-container uk-container-${displayWidth} uk-position-relative`}>
                <div data-uk-slider={dis.carousel ? "autoplay: true" : "false"}>
                    <div
                        className={`${dis.carousel ? "uk-slider-items" : ""} ${style.cardCollection} uk-grid uk-grid-xxsmall ${dis.count ? "uk-child-width-1-2" : (cards.length <= 2 || displayWidth === "xsmall") ? "uk-child-width-1-2@m" : "uk-child-width-1-4@l"} uk-grid-match`}
                        data-uk-height-match="target: .uk-card-body">
                        {cards.map((card, i) =>
                            <div key={card.slug}>
                                <div className={`${cards.length <= 2 ? style.cardItemLarge : (displayWidth === "small" || displayWidth === "xsmall") ? style.cardItemSmall : style.cardItem} uk-card ${customColor(card.sectionColor)}`}>
                                    <div className="uk-card-media-top uk-position-relative">
                                        <Img
                                            fluid={cards[i].image.localFile.childImageSharp.fluid}
                                            alt={card.image.title}
                                            objectFit="cover"
                                            objectPosition="50% 50%"
                                        />
                                        <div className="uk-overlay uk-position-bottom-center uk-width-1-1">
                                            {card.title ?
                                                <p className="uk-text-bolder uk-text-uppercase uk-light">
                                                    {card.title}
                                                </p>
                                                : null}
                                        </div>
                                    </div>
                                    <div className={`uk-card-body uk-height-min-medium uk-flex uk-flex-middle uk-flex-top@m`}>
                                        {card.copy.copy ?
                                            <p className="uk-text-medium uk-light" dangerouslySetInnerHTML={{ __html: card.copy.copy }} />
                                            : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

// const HeroCarousel = ({collectionName, heroCtaModules, slug}) => {

//     return (
//         <div
//             className={`uk-position-relative uk-light`}
//             data-uk-slideshow="autoplay: true;autoplay-interval: 5000;animation: fade; min-height: 512; max-height: 800; pause-on-hover: false">
//                 <ul className="uk-slideshow-items uk-grid uk-grid-collapse uk-grid-match uk-child-width-1-1">
//                     {heroCtaModules.map((item, id) =>
//                         <li key={item.slug}>
//                             <HeroDefault data={item} />
//                         </li>
//                     )}
//                 </ul>
//                 <ul className="uk-position-bottom-center uk-position-large uk-slideshow-nav uk-dotnav"></ul>
//         </div>
//     )
// }

function IndexPage({ data, location, ...props }) {
    const content = data.contentfulHomePage;
    const blog = data.allContentfulBlogPost.edges;
    const title = `${content.pageTitle}`;
    // const description = `${content.metaDescription}`;
    const metaImage = `https:${content.metaImage.file.url}`;
    const ctaCarousel = data.allContentfulHeroCarousel.nodes[0].heroCtaModules
    const siftUI = data.allFile.nodes[0];
    const aboutCards = data.allContentfulCardCollection.nodes[0]

    return (
        <Layout location={location}>
            <SEO title={title} />
            <Helmet>
                <meta name="image" content={metaImage} />
                <meta property="og:image" content={metaImage} />
                <meta name="twitter:image" content={metaImage} />
            </Helmet>
            <div
                style={{
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                    // backgroundImage: `url(${heroBG})`,
                    backgroundColor: "#000",
                    marginTop: "72px"
                }}
                className="about__header uk-height-min-large uk-cover-container uk-overflow-hidden uk-toggle">
                <div style={{ backgroundColor: "rgba(29, 38, 54, 0.6)", zIndex: "1" }} className="uk-position-cover" />
                <div className="vimeo__wrapper">
                    <iframe
                        src={`https://player.vimeo.com/video/784692082?h=397d069e75&background=1&muted=1&autoplay=1&loop=1&color=000000`}
                        allow="autoplay; fullscreen; picture-in-picture"
                        webkitallowfullscreen={`true`}
                        mozallowfullscreen={`true`}
                        allowFullScreen
                        data-uk-video="autoplay: inview;"
                    />
                </div>
                <div style={{ zIndex: "2" }} className="uk-padding-small uk-height-1-1 uk-position-relative uk-light">
                    <div className="uk-flex uk-flex-middle uk-height-1-1">
                        <div className="uk-container">
                            <div className="uk-width-3-5@s">
                                <p className="uk-text-lead uk-margin-remove-vertical">In a world of uncertainty</p>
                                <h1 className="h1__xxlarge uk-text-primary uk-margin-remove-vertical">We're in the business of certainty.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="it-started-as-a-hobby" class="uk-margin-xxsmall uk-section uk-background-wine-light uk-light">
                <div class="uk-container uk-container-medium  uk-padding">
                    <p className="uk-text-xlarge uk-text-bold uk-light">What started as a hobby, became our passion.</p>
                    <p className="uk-text-large uk-light">A passion for solving problems, for finding answers, for creating order in an unpredictable world.</p>
                    <p className="uk-text-large uk-light">The passion that fuels our work as a leading provider of solutions for global consumer goods companies.</p>
                </div>
            </section>
            <CardCollection data={aboutCards} cards={aboutCards.cards} />
            <HeroCarousel heroCtaModules={ctaCarousel} slug={data.allContentfulHeroCarousel.nodes[0].slug} />

            <section className="uk-section uk-section-large uk-background-grape">
                <div className="uk-container uk-container-expand uk-flex uk-flex-center">
                    <div className="uk-width-5-6 uk-width-4-5@m">
                        <div className="uk-grid uk-grid-large uk-grid-row-small uk-child-width-1-2@s">
                            <div>
                                <div>
                                    <Img
                                        fluid={siftUI.childImageSharp.fluid}
                                        alt="More Accountable."
                                        objectFit="cover"
                                        objectPosition="50% 50%"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                            <div className="uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-width-4-5@s">
                                    <p className="h1__large uk-text-grape-lighter">Clarity<br />in the chaos.</p>
                                    <p className="uk-text-lead uk-light uk-text-grape-lightest">Sift helps filter out the noise and unnecessary in the sea of information influencing your business.</p>
                                    <div className="uk-margin-top">
                                        <ButtonArrowRight
                                            url={`/sift/`}
                                            label="Learn more"
                                            css={`uk-button-large cta-button uk-text-grape-lightest`}
                                            color={`#DFDBE3`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <LatestPosts blog={blog} />

        </Layout>
    );
}

export default IndexPage;

export const homeQuery = graphql`
    query homeQuery {
        contentfulHomePage(site: {eq: "SoleraAdvisors"}) {
            content {
                json
            }
            canonicalLink
            metaDescription
            metaImage {
                file {
                    url
                }
            }
            metaKeywords
            navigationLabel
            pageTitle
        }
        allContentfulBlogPost(filter: {site: {eq: "SoleraAdvisors"}}) {
            edges {
              node {
                copy {
                  childMarkdownRemark {
                    html
                    rawMarkdownBody
                  }
                }
                slug
                title
                publishDate
                topic
                heroVideo
                sectionColor
                heroImage {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          }
          allFile(filter: {name: {eq: "sift-ui"}}) {
            nodes {
              childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
              name
            }
          }
          allContentfulHeroCarousel(filter: {slug: {eq: "home-cta-carousel"}}) {
            nodes {
              slug
              heroCtaModules {
                templateLayout
                slug
                headline
                subHeadline {
                  childMarkdownRemark {
                    html
                  }
                }
                imageFile {
                  localFile {
                    childImageSharp {
                        fluid(maxWidth: 1600) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
          }
          allContentfulCardCollection(filter: {slug: {eq: "about-us-cards"}}) {
            nodes {
              slug
              headline
              subHeadline {
                subHeadline
              }
              collectionName
              displayFormat
              displayWidth
              sectionColor
              cards {
                ... on ContentfulSectionItem {
                  slug
                  title
                  sectionColor
                  displayWidth
                  image {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  copy {
                    copy
                  }
                }
              }
            }
          }
    }`





